import React from 'react'

const CardUser = ({ children }) => {
    return (
        <div className="navbar-user">
            {children}

        </div>
    )
}

export default CardUser